import React, { useState, useEffect } from 'react';
import { ChevronRight, BarChart, Mail, Search, Globe, Share2, TrendingUp, X, Menu } from 'lucide-react';
import { useSpring, animated } from 'react-spring';

// Dynamic EmailJS loading function
const loadEmailJS = () => {
  return new Promise((resolve, reject) => {
    const script = document.createElement('script');
    script.src = 'https://cdn.jsdelivr.net/npm/@emailjs/browser@3/dist/email.min.js';
    script.async = true;
    script.onload = () => {
      if (window.emailjs) {
        window.emailjs.init("Ch3n7vZF5XMYt4p_W");
        resolve(window.emailjs);
      } else {
        reject(new Error('EmailJS failed to load'));
      }
    };
    script.onerror = () => reject(new Error('Failed to load EmailJS'));
    document.body.appendChild(script);
  });
};

// Arabic translations
const translations = {
  en: {
    services: "Services",
    pricing: "Pricing",
    contact: "Contact",
    heroTitle: "Elevate Your Digital Presence with Katbi Digital Solutions",
    heroSubtitle: "Comprehensive digital marketing strategies to boost your online success",
    getConsultation: "Get a Free Consultation",
    ourServices: "Our Services",
    googleAdsTitle: "Google Ads Management",
    googleAdsDesc: "Optimize your PPC campaigns for maximum ROI and targeted reach.",
    analyticsTitle: "Google Analytics",
    analyticsDesc: "Gain actionable insights from your website traffic to drive data-informed decisions.",
    seoTitle: "SEO Optimization",
    seoDesc: "Improve your organic search rankings and drive more quality traffic to your website.",
    socialMediaTitle: "Social Media Marketing",
    socialMediaDesc: "Engage your audience and build brand awareness across popular social platforms.",
    emailMarketingTitle: "Email Marketing",
    emailMarketingDesc: "Create targeted email campaigns to nurture leads and boost customer retention.",
    conversionTitle: "Conversion Rate Optimization",
    conversionDesc: "Enhance your website's performance to convert more visitors into customers.",
    pricingTitle: "Pricing",
    starterPlan: "Starter",
    growthPlan: "Growth",
    enterprisePlan: "Enterprise",
    starterPrice: "AED 1,499",
    growthPrice: "AED 2,999",
    enterprisePrice: "Custom",
    choosePlan: "Choose Plan",
    readyToTransform: "Ready to Transform Your Digital Presence?",
    getConsultationToday: "Get your free digital marketing consultation today",
    startJourney: "Start Your Digital Journey",
    footer: "© 2024 Katbi Digital Solutions. All rights reserved.",
    formTitle: "Get Your Free Consultation",
    nameLabel: "Name",
    emailLabel: "Email",
    phoneLabel: "Phone",
    messageLabel: "Message",
    submitButton: "Submit",
    thankYouMessage: "Thank You!",
    messageSentConfirmation: "Your message has been sent to our support team. We'll get back to you soon.",
  },
  ar: {
    services: "الخدمات",
    pricing: "الأسعار",
    contact: "اتصل بنا",
    heroTitle: "ارتقِ بحضورك الرقمي مع كاتبي للحلول الرقمية",
    heroSubtitle: "استراتيجيات تسويق رقمي شاملة لتعزيز نجاحك عبر الإنترنت",
    getConsultation: "احصل على استشارة مجانية",
    ourServices: "خدماتنا",
    googleAdsTitle: "إدارة إعلانات جوجل",
    googleAdsDesc: "تحسين حملات الدفع لكل نقرة لتحقيق أقصى عائد على الاستثمار والوصول المستهدف.",
    analyticsTitle: "تحليلات جوجل",
    analyticsDesc: "احصل على رؤى قابلة للتنفيذ من حركة المرور على موقعك لاتخاذ قرارات مستندة إلى البيانات.",
    seoTitle: "تحسين محركات البحث",
    seoDesc: "تحسين ترتيبك في نتائج البحث العضوية وجذب المزيد من الزيارات ذات الجودة إلى موقعك.",
    socialMediaTitle: "التسويق عبر وسائل التواصل الاجتماعي",
    socialMediaDesc: "تفاعل مع جمهورك وبناء الوعي بالعلامة التجارية عبر منصات التواصل الاجتماعي الشهيرة.",
    emailMarketingTitle: "التسويق عبر البريد الإلكتروني",
    emailMarketingDesc: "إنشاء حملات بريد إلكتروني مستهدفة لتنمية العملاء المحتملين وتعزيز الاحتفاظ بالعملاء.",
    conversionTitle: "تحسين معدل التحويل",
    conversionDesc: "تحسين أداء موقعك الإلكتروني لتحويل المزيد من الزوار إلى عملاء.",
    pricingTitle: "الأسعار",
    starterPlan: "الأساسية",
    growthPlan: "النمو",
    enterprisePlan: "المؤسسات",
    starterPrice: "1,499 درهم",
    growthPrice: "2,999 درهم",
    enterprisePrice: "حسب الطلب",
    choosePlan: "اختر الخطة",
    readyToTransform: "هل أنت مستعد لتحويل حضورك الرقمي؟",
    getConsultationToday: "احصل على استشارة التسويق الرقمي المجانية اليوم",
    startJourney: "ابدأ رحلتك الرقمية",
    footer: "© 2024 كاتبي للحلول الرقمية. جميع الحقوق محفوظة.",
    formTitle: "احصل على استشارتك المجانية",
    nameLabel: "الاسم",
    emailLabel: "البريد الإلكتروني",
    phoneLabel: "رقم الهاتف",
    messageLabel: "الرسالة",
    submitButton: "إرسال",
    thankYouMessage: "شكراً لك!",
    messageSentConfirmation: "تم إرسال رسالتك إلى فريق الدعم لدينا. سنعود إليك قريباً.",
  }
};

const Form = ({ onClose, lang }) => {
  const formAnimation = useSpring({
    from: { opacity: 0, transform: 'scale(0.9)' },
    to: { opacity: 1, transform: 'scale(1)' },
    config: { tension: 400, friction: 22, clamp: true},
  });

  const [formData, setFormData] = useState({
    name: '',
    email: '',
    phone: '',
    message: ''
  });

  const [isSubmitted, setIsSubmitted] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    setError(null);

    try {
      const emailjs = await loadEmailJS();
      await emailjs.send("service_0suumhr", "template_hu1h89w", {
        'contact-name': formData.name,
        'contact-email': formData.email,
        'contact-phone': formData.phone,
        'contact-message': formData.message,
      });
      setIsSubmitted(true);
      setTimeout(() => {
        onClose();
      }, 3000);
    } catch (err) {
      console.error('Failed to send email:', err);
      setError("Failed to send email. Please try again later.");
    } finally {
      setIsLoading(false);
    }
  };

  if (isSubmitted) {
    return (
      <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center p-4 z-50">
        <animated.div style={formAnimation} className="bg-white rounded-lg p-8 max-w-md w-full relative">
          <h2 className="text-2xl font-bold mb-4 text-green-600">{translations[lang].thankYouMessage}</h2>
          <p>{translations[lang].messageSentConfirmation}</p>
        </animated.div>
      </div>
    );
  }

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center p-4 z-50">
      <animated.div style={formAnimation} className="bg-white rounded-lg p-8 max-w-md w-full relative">
        <button onClick={onClose} className="absolute top-2 right-2 transition-transform hover:scale-110">
          <X size={24} />
        </button>
        <h2 className="text-2xl font-bold mb-4">{translations[lang].formTitle}</h2>
        {error && <p className="text-red-500 mb-4">{error}</p>}
        <form onSubmit={handleSubmit}>
          <div className="mb-4">
            <label htmlFor="name" className="block mb-2">{translations[lang].nameLabel}</label>
            <input
              type="text"
              id="name"
              name="name"
              value={formData.name}
              onChange={handleChange}
              className="w-full p-2 border rounded"
              required
            />
          </div>
          <div className="mb-4">
            <label htmlFor="email" className="block mb-2">{translations[lang].emailLabel}</label>
            <input
              type="email"
              id="email"
              name="email"
              value={formData.email}
              onChange={handleChange}
              className="w-full p-2 border rounded"
              required
            />
          </div>
          <div className="mb-4">
            <label htmlFor="phone" className="block mb-2">{translations[lang].phoneLabel}</label>
            <input
              type="tel"
              id="phone"
              name="phone"
              value={formData.phone}
              onChange={handleChange}
              className="w-full p-2 border rounded"
              required
            />
          </div>
          <button 
            label="Get 30% Off"
            type="submit" 
            className="w-full bg-blue-500 text-white font-bold py-2 px-4 rounded hover:bg-blue-600 transition-colors duration-300"
            disabled={isLoading}
          >
            {isLoading ? 'Sending...' : translations[lang].submitButton}
          </button>
        </form>
      </animated.div>
    </div>
  );
};

const FeatureItem = ({ icon: Icon, title, description, lang }) => {
  const [isHovered, setIsHovered] = useState(false);
  const springProps = useSpring({
    transform: isHovered ? 'scale(1.05)' : 'scale(1)',
    boxShadow: isHovered ? '0 10px 20px rgba(0,0,0,0.1)' : '0 0 0 rgba(0,0,0,0)',
    config: { tension: 300, friction: 10, clamp: true},
  });

  return (
    <animated.div
      style={springProps}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
      className="flex flex-col items-center p-4 rounded-lg transition-all duration-300 h-full"
    >
      <Icon className="text-blue-500 mb-4" size={24} />
      <div className="text-center">
        <h4 className="text-xl font-semibold mb-2">{translations[lang][title]}</h4>
        <p>{translations[lang][description]}</p>
      </div>
    </animated.div>
  );
};

const PricingCard = ({ plan, price, features, onChoose, lang }) => {
  const [isHovered, setIsHovered] = useState(false);
  const springProps = useSpring({
    transform: isHovered ? 'translateY(-10px)' : 'translateY(0px)',
    boxShadow: isHovered ? '0 20px 30px rgba(0,0,0,0.2)' : '0 0 0 rgba(0,0,0,0)',
    config: { tension: 300, friction: 10, clamp: true},
  });

  return (
    <animated.div
      style={springProps}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
      className="bg-white p-8 rounded-lg transition-all duration-300 w-full max-w-sm"
    >
      <div className="text-center">
        <h4 className="text-2xl font-semibold mb-4">{translations[lang][plan]}</h4>
        <p className="text-gray-600 mb-4">
          {lang === 'en' ? `Perfect for ${plan === 'starterPlan' ? 'small' : plan === 'growthPlan' ? 'growing' : 'large'} businesses` : 
                           `مثالي للشركات ${plan === 'starterPlan' ? 'الصغيرة' : plan === 'growthPlan' ? 'النامية' : 'الكبيرة'}`}
        </p>
        <p className="text-3xl font-bold mb-4">{translations[lang][price]}<span className="text-sm font-normal">{lang === 'en' ? '/month' : '/شهر'}</span></p>
      </div>
      <ul className="mb-8">
        {features.map((feature, index) => (
          <li key={index} className="flex items-center mb-2">
          <ChevronRight className="text-blue-500 mr-2 flex-shrink-0" size={16} />
          <span>{feature}</span>
        </li>
      ))}
    </ul>
    <button onClick={onChoose} className="w-full bg-blue-500 text-white font-bold py-2 px-4 rounded-full hover:bg-blue-600 transition-colors duration-300">
      {translations[lang].choosePlan}
    </button>
  </animated.div>
);
};

const MobileMenu = ({ isOpen, onClose, lang, setLang, scrollToSection }) => {
const slideIn = useSpring({
  transform: isOpen ? 'translateX(0%)' : 'translateX(-100%)',
  opacity: isOpen ? 1 : 0,
});

return (
  <animated.div 
    style={slideIn}
    className={`fixed top-0 left-0 w-64 h-full bg-white shadow-lg z-50 ${lang === 'ar' ? 'rtl' : 'ltr'}`}
  >
    <div className="p-4">
      <button onClick={onClose} className="absolute top-2 right-2 p-2">
        <X size={24} />
      </button>
      <div className="flex mb-6">
        <img src="logo-normal.webp" alt="Katbi Digital Solutions" className="h-20" />
      </div>
      <nav className="mt-8">
        <ul className="space-y-4">
          <li>
            <button onClick={() => { scrollToSection('features'); onClose(); }} className="w-full text-left py-2">
              {translations[lang].services}
            </button>
          </li>
          <li>
            <button onClick={() => { scrollToSection('pricing'); onClose(); }} className="w-full text-left py-2">
              {translations[lang].pricing}
            </button>
          </li>
          <li>
            <button onClick={() => { scrollToSection('contact'); onClose(); }} className="w-full text-left py-2">
              {translations[lang].contact}
            </button>
          </li>
          <li>
            <button onClick={() => setLang(lang === 'en' ? 'ar' : 'en')} className="w-full text-left py-2">
              {lang === 'en' ? 'العربية' : 'English'}
            </button>
          </li>
        </ul>
      </nav>
    </div>
  </animated.div>
);
};

const NavMenu = ({ isMobile, lang, setLang, scrollToSection }) => (
<nav className={isMobile ? "hidden" : "block"}>
  <ul className="flex space-x-4">
    <li><button onClick={() => scrollToSection('features')} className="hover:underline transition-colors duration-300 text-black">{translations[lang].services}</button></li>
    <li><button onClick={() => scrollToSection('pricing')} className="hover:underline transition-colors duration-300 text-black">{translations[lang].pricing}</button></li>
    <li><button onClick={() => scrollToSection('contact')} className="hover:underline transition-colors duration-300 text-black">{translations[lang].contact}</button></li>
    <li><button onClick={() => setLang(lang === 'en' ? 'ar' : 'en')} className="hover:underline transition-colors duration-300 text-black">{lang === 'en' ? 'العربية' : 'English'}</button></li>
  </ul>
</nav>
);

const LandingPage = () => {
const [showForm, setShowForm] = useState(false);
const [lang, setLang] = useState('en');
const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);
const [isMobile, setIsMobile] = useState(false);

useEffect(() => {
  const checkIsMobile = () => {
    setIsMobile(window.innerWidth < 768); // Adjust this breakpoint as needed
  };
  checkIsMobile();
  window.addEventListener('resize', checkIsMobile);
  return () => window.removeEventListener('resize', checkIsMobile);
}, []);

useEffect(() => {
  // Preload EmailJS when the component mounts
  loadEmailJS().catch(console.error);
}, []);

const openForm = () => setShowForm(true);
const closeForm = () => setShowForm(false);

const fadeIn = useSpring({
  from: { opacity: 0, transform: 'translateY(20px)' },
  to: { opacity: 1, transform: 'translateY(0)' },
  config: { duration: 500 },
});

const scrollToSection = (sectionId) => {
  const section = document.getElementById(sectionId);
  if (section) {
    section.scrollIntoView({ behavior: 'smooth' });
  }
};

return (
  <div className={`min-h-screen bg-white text-gray-800 font-sans ${lang === 'ar' ? 'rtl' : 'ltr'}`}>
    {/* Header */}
    <header className="bg-white sticky top-0 z-10 shadow-md">
      <div className="container mx-auto px-4 py-2 flex justify-between items-center">
        <img src="logo-normal.webp" alt="Katbi Digital Solutions" className="h-16" />
        <NavMenu isMobile={isMobile} lang={lang} setLang={setLang} scrollToSection={scrollToSection} />
        {isMobile && (
          <button onClick={() => setIsMobileMenuOpen(true)} className="p-2 text-black">
            <Menu size={24} />
          </button>
        )}
      </div>
    </header>

    {/* Mobile Menu */}
    <MobileMenu 
      isOpen={isMobileMenuOpen} 
      onClose={() => setIsMobileMenuOpen(false)} 
      lang={lang} 
      setLang={setLang}
      scrollToSection={scrollToSection}
    />

    {/* Hero Section */}
    <animated.section style={fadeIn} className="bg-blue-500 text-white py-20">
      <div className="container mx-auto px-4 text-center">
        <h2 className="text-4xl font-bold mb-4">{translations[lang].heroTitle}</h2>
        <p className="text-xl mb-8 max-w-2xl mx-auto">{translations[lang].heroSubtitle}</p>
        <button onClick={openForm} className="bg-white text-blue-500 font-bold py-2 px-4 rounded-full hover:bg-blue-100 transition-all duration-300 transform hover:scale-105">
          {translations[lang].getConsultation}
        </button>
      </div>
    </animated.section>

    {/* Features Section */}
    <section id="features" className="py-20">
      <div className="container mx-auto px-4">
        <h3 className="text-3xl font-bold text-center mb-12">{translations[lang].ourServices}</h3>
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8 justify-items-center">
          <FeatureItem icon={Search} title="googleAdsTitle" description="googleAdsDesc" lang={lang} />
          <FeatureItem icon={BarChart} title="analyticsTitle" description="analyticsDesc" lang={lang} />
          <FeatureItem icon={Globe} title="seoTitle" description="seoDesc" lang={lang} />
          <FeatureItem icon={Share2} title="socialMediaTitle" description="socialMediaDesc" lang={lang} />
          <FeatureItem icon={Mail} title="emailMarketingTitle" description="emailMarketingDesc" lang={lang} />
          <FeatureItem icon={TrendingUp} title="conversionTitle" description="conversionDesc" lang={lang} />
        </div>
      </div>
    </section>

    {/* Pricing Section */}
    <section id="pricing" className="bg-gray-100 py-20">
      <div className="container mx-auto px-4">
        <h3 className="text-3xl font-bold text-center mb-12">{translations[lang].pricingTitle}</h3>
        <div className="grid grid-cols-1 md:grid-cols-3 gap-8 justify-items-center">
          <PricingCard
            plan="starterPlan"
            price="starterPrice"
            features={[translations[lang].googleAdsTitle, translations[lang].analyticsTitle, translations[lang].socialMediaTitle]}
            onChoose={openForm}
            lang={lang}
          />
          <PricingCard
            plan="growthPlan"
            price="growthPrice"
            features={[translations[lang].googleAdsTitle, translations[lang].analyticsTitle, translations[lang].seoTitle, translations[lang].emailMarketingTitle]}
            onChoose={openForm}
            lang={lang}
          />
          <PricingCard
            plan="enterprisePlan"
            price="enterprisePrice"
            features={[translations[lang].googleAdsTitle, translations[lang].analyticsTitle, translations[lang].seoTitle, translations[lang].socialMediaTitle, translations[lang].emailMarketingTitle, translations[lang].conversionTitle]}
            onChoose={openForm}
            lang={lang}
          />
        </div>
      </div>
    </section>

    {/* CTA Section */}
    <section id="contact" className="bg-blue-600 text-white py-20">
      <div className="container mx-auto px-4 text-center">
        <h3 className="text-3xl font-bold mb-4">{translations[lang].readyToTransform}</h3>
        <p className="text-xl mb-8 max-w-2xl mx-auto">{translations[lang].getConsultationToday}</p>
        <button onClick={openForm} className="bg-white text-blue-500 font-bold py-2 px-4 rounded-full hover:bg-blue-100 transition-all duration-300 transform hover:scale-105">
          {translations[lang].startJourney}
        </button>
      </div>
    </section>

    {/* Footer */}
    <footer className="bg-gray-800 text-white py-8">
      <div className="container mx-auto px-4 text-center">
        <p>{translations[lang].footer}</p>
      </div>
    </footer>

    {/* WhatsApp Button */}
    <a
      href="https://wa.me/+971505733543"
      target="_blank"
      rel="noopener noreferrer"
      className="fixed bottom-4 right-4 bg-green-500 text-white rounded-full p-3 hover:bg-green-600 transition-colors duration-300 z-50"
    >
      <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="currentColor">
        <path d="M.057 24l1.687-6.163c-1.041-1.804-1.588-3.849-1.587-5.946.003-6.556 5.338-11.891 11.893-11.891 3.181.001 6.167 1.24 8.413 3.488 2.245 2.248 3.481 5.236 3.48 8.414-.003 6.557-5.338 11.892-11.893 11.892-1.99-.001-3.951-.5-5.688-1.448l-6.305 1.654zm6.597-3.807c1.676.995 3.276 1.591 5.392 1.592 5.448 0 9.886-4.434 9.889-9.885.002-5.462-4.415-9.89-9.881-9.892-5.452 0-9.887 4.434-9.889 9.884-.001 2.225.651 3.891 1.746 5.634l-.999 3.648 3.742-.981zm11.387-5.464c-.074-.124-.272-.198-.57-.347-.297-.149-1.758-.868-2.031-.967-.272-.099-.47-.149-.669.149-.198.297-.768.967-.941 1.165-.173.198-.347.223-.644.074-.297-.149-1.255-.462-2.39-1.475-.883-.788-1.48-1.761-1.653-2.059-.173-.297-.018-.458.13-.606.134-.133.297-.347.446-.521.151-.172.2-.296.3-.495.099-.198.05-.372-.025-.521-.075-.148-.669-1.611-.916-2.206-.242-.579-.487-.501-.669-.51l-.57-.01c-.198 0-.52.074-.792.372s-1.04 1.016-1.04 2.479 1.065 2.876 1.213 3.074c.149.198 2.095 3.2 5.076 4.487.709.306 1.263.489 1.694.626.712.226 1.36.194 1.872.118.571-.085 1.758-.719 2.006-1.413.248-.695.248-1.29.173-1.414z"/>
      </svg>
    </a>

    {showForm && <Form onClose={closeForm} lang={lang} />}
  </div>
);
};

function App() {
return (
  <LandingPage />
);
}

export default App;